import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import EvenNumberIcon from './images/circle-green.inline.svg';
import OddNumberIcon from './images/circle-purple.inline.svg';
import WavesBg from './images/waves.inline.svg';

const steps = [
  {
    number: 1,
    text: 'Together, we begin our discussion of your data and its attributes',
    isHighlighted: false,
  },
  {
    number: 2,
    text: 'We analyze how consent is sourced and the transparency around data collection',
    isHighlighted: false,
  },
  {
    number: 3,
    text: 'We examine how accurate your data is and any potential bias or sampling limitations',
    isHighlighted: false,
  },
  {
    number: 4,
    text: 'We determine the predictive quality of your dataset in accurately identifying future events',
    isHighlighted: false,
  },
  {
    number: 5,
    text: 'Once testing is finalized, together we evaluate the partnership opportunity',
    isHighlighted: false,
  },
  {
    number: 6,
    text: 'We organize transfers and integrations then unify our datasets',
    isHighlighted: false,
  },
  { number: 7, text: 'You start monetizing!', isHighlighted: true },
];

const evenSteps = steps.filter((item) => item.number % 2 === 0);
const oddSteps = steps.filter((item) => item.number % 2 === 1);

const TextItem = ({ number, text, isHighlighted = false, className }) => {
  const isEvenNumber = number % 2 === 0;
  return (
    <div
      className={`flex flex-col ${isEvenNumber && 'xl:flex-col-reverse'} items-center ${
        className || ''
      }`}
    >
      <div className="relative flex-shrink-0 w-9 h-9">
        {isEvenNumber ? <EvenNumberIcon /> : <OddNumberIcon />}
        <span className="absolute font-bold text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          {number}
        </span>
      </div>
      <p
        className={`text-xl xl:text-base text-center leading-tight xl:leading-tight text-white mt-4 ${
          isEvenNumber ? 'xl:mb-4 xl:mt-0' : 'xl:mt-4'
        } ${isHighlighted && 'font-bold'}`}
      >
        {text}
      </p>
    </div>
  );
};

const ReviewProcess = () => (
  <section className="pt-20 pb-20 lg:pt-40 lg:pb-40">
    <Container>
      <div className="flex flex-col items-center px-8 pt-16 pb-16 overflow-hidden gradient-background-blue xl:px-0 rounded-2xl">
        <Heading className="mb-12 lg:mb-16" tag="h2" size="lg" theme="white">
          Review Process
        </Heading>
        {/* hide this section with visibility: hidden, because display:none
        breaks SVG fill https://stackoverflow.com/questions/49531434/hidding-svg-affects-other-svg-styles-in-the-same-page */}
        <div className="relative invisible w-full h-0 -ml-16 -mr-16 xl:visible xl:h-auto xl:pb-20 ">
          <WavesBg />
          <div className="absolute left-0 grid grid-cols-3 top-4 pl-44 pr-44 gap-x-9">
            {evenSteps.map((item) => (
              <TextItem text={item.text} number={item.number} key={item.number} />
            ))}
          </div>
          <div className="absolute left-0 grid grid-cols-4 pl-10 pr-10 -bottom-2.5 gap-x-14">
            {oddSteps.map((item) => (
              <TextItem
                text={item.text}
                number={item.number}
                isHighlighted={item.isHighlighted}
                key={item.number}
              />
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-32 gap-y-8 md:gap-y-16 md: xl:invisible xl:h-0">
          {steps.map((item, index) => (
            <TextItem
              className={index === steps.length - 1 && 'md:col-start-1 md:col-end-3 md:w-full'}
              text={item.text}
              number={item.number}
              isHighlighted={item.isHighlighted}
              key={item.number}
            />
          ))}
        </div>
      </div>
    </Container>
  </section>
);

export default ReviewProcess;
