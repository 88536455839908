import React from 'react';

import CardWithIcon from 'components/shared/card-with-icon';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import AccountingIcon from './images/accounting.inline.svg';
import PrivacyIcon from './images/privacy.inline.svg';
import RevenueIcon from './images/revenue.inline.svg';

const items = [
  {
    icon: RevenueIcon,
    title: 'Increased Revenue',
    description: 'Safely unlock new monetization opportunities in partnership with Yobi.',
  },
  {
    icon: AccountingIcon,
    title: 'Transparent Accounting',
    description:
      'Access a private dashboard to forensically analyze all usage of your data throughout Yobi’s ecosystem.',
  },
  {
    icon: PrivacyIcon,
    title: 'Privacy Preserving',
    description:
      'Yobi’s Technology enables you to preserve the value of your data and the privacy of your customers.',
  },
];

const Benefits = () => (
  <section className="pt-20 pb-20 lg:pt-40 lg:pb-40 gradient-background-gray">
    <Container>
      <Heading className="text-center" tag="h2" size="lg" theme="black">
        Benefits of Yobi’s Platform
      </Heading>
      <div className="grid grid-cols-1 gap-8 mt-12 lg:mt-16 lg:grid-cols-3">
        {items.map(({ icon: Icon, title, description }, index) => (
          <CardWithIcon
            title={title}
            description={description}
            icon={Icon}
            key={index}
            theme="white"
          />
        ))}
      </div>
    </Container>
  </section>
);

export default Benefits;
