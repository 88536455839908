import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Logo from 'images/logo-white.inline.svg';

import CheckIcon from './images/check.inline.svg';
import CrossIcon from './images/cross.inline.svg';

const features = [
  'Privacy Preserving',
  'Predictive and Cleansed',
  'Increase Value of Data',
  'No Technical Experience Required',
];

const CompetitiveDifference = () => (
  <section className="relative pt-20 pb-32 lg:pt-40 lg:pb-40 -mb-44 gradient-background-gray after:absolute after:left-0 after:-bottom-96 after:gradient-background-gray after:w-full after:h-96">
    <Container>
      <Heading
        className="mb-12 text-center lg:mb-16"
        tag="h2"
        size="lg"
        theme="black"
        withHighlightedWords
      >
        Yobi's Competitive Difference
      </Heading>
      <div className="-my-2 -mr-4 overflow-x-auto md:-mx-6 lg:-mx-8 xl:mx-0 ">
        <div className="inline-block min-w-full pb-10 pr-4 align-middle md:px-6 lg:px-8 ">
          <div className="overflow-hidden shadow-xl rounded-2xl">
            <table className="table min-w-full">
              <thead className="bg-white">
                <tr>
                  <th scope="col" className="px-6 py-6" />
                  <th scope="col" className="px-6 py-6 gradient-background-contact-form">
                    <Logo className="mx-auto" />
                  </th>
                  <th scope="col" className="px-6 py-6 font-medium ">
                    Data Broker
                  </th>
                  <th scope="col" className="px-6 py-6 font-medium">
                    Data Marketplace
                  </th>
                  <th scope="col" className="px-6 py-6 font-medium ">
                    Programmatic Audiences
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {features.map((feature, index) => (
                  <tr key={feature} className={`border-none ${index % 2 === 0 ? 'bg-gray-8' : ''}`}>
                    <td className="px-8 py-4">
                      <span className="font-bold">{feature}</span>
                    </td>
                    <td
                      className={`relative px-6 py-4 gradient-background-contact-form ${
                        index % 2 === 0 &&
                        'before:absolute before:top-0 before:right-0 before:left-0 before:bottom-0 before:bg-white before:opacity-10'
                      }`}
                    >
                      <div>
                        <CheckIcon className="mx-auto" />
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <CrossIcon className="mx-auto" />
                    </td>
                    <td className="px-6 py-4">
                      <CrossIcon className="mx-auto" />
                    </td>
                    <td className="px-6 py-4">
                      <CrossIcon className="mx-auto" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  </section>
);

export default CompetitiveDifference;
