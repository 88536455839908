import React from 'react';

import Benefits from 'components/pages/data-monetization/benefits/benefits';
import CompetitiveDifference from 'components/pages/data-monetization/competitive-difference';
import Hero from 'components/pages/data-monetization/hero';
import ReviewProcess from 'components/pages/data-monetization/review-process/review-process';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const DataMonetization = () => (
  <MainLayout pageMetadata={SeoMetadata['data-monetization']}>
    <Hero />
    <Benefits />
    <ReviewProcess />
    <CompetitiveDifference />
  </MainLayout>
);

export default DataMonetization;
