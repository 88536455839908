import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import HeroWithImage from 'components/shared/hero-with-image/hero-with-image';

const pageTitle = 'Data Monetization Redefined';
const pageDescription =
  'Introducing a new way to monetize data without sacrificing customer privacy. Yobi’s Behavioral Genome extracts signal from datasets without revealing personal or descriptive information. Companies can use Yobi’s technology to protect customer privacy in addition to preventing the commoditization of their valuable data asset.';

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "pages/data-monetization/hero/image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 700)
        }
      }
    }
  `);
  return <HeroWithImage title={pageTitle} description={pageDescription} image={image} />;
};

export default Hero;
